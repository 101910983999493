<script setup>
import { useScreenTab } from "./useScreenTab";

const el = ref(null);
const tab = ref(null);
const tabContainer = inject("screenTabs");

provide(
  "tab",
  computed(() => screen.value)
);

//when root el is rendered, register screen
watchEffect(() => register(el.value));

function register() {
  if (!el.value) return;

  tab.value = useScreenTab(computed(() => el.value));
  tabContainer.register(tab.value);
}
</script>
<template>
  <div ref="el" class="v-tab tab-margin">
    <slot></slot>
  </div>
</template>

<style scoped>
.v-tab {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  /* background-color: var(--dialog-bg); */
  background: #fff;

  z-index: 0;
  overscroll-behavior: auto;
  color: var(--text-color);
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  /* padding-left: var(--tab-padding); */
  /* padding-right: var(--tab-padding); */
  visibility: hidden;
}

.transition {
  transition: 240ms all var(--layer-transition);
  will-change: transform;
}

.tab-margin {
  /* padding-top: var(--header-height); */
  /* padding-bottom: var(--footer-height); */
  box-sizing: inherit;
}

.back-before {
  scale: 1;
  opacity: 1;
  transform: translate3d(0%, 0%, 0) !important;
  will-change: transform, opacity;
}

.back{
  scale: 0.85;
  opacity: 0.95;
  transform: translate3d(0%, 10%, 0);
  will-change: transform, opacity;
}

.top {
  scale: 1 !important;
  opacity: 1 !important;
  transform: translate3d(0%, 0%, 0) !important;
  will-change: transform, opacity;
}

.top-before {
  scale: 0.7;
  opacity: 0.95;
  transform: translate3d(0%, 50%, 0);
  will-change: transform, opacity;
}
</style>
./useScreenTab